import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_2beifen from '../views/lanhu_2beifen/index.vue'
import lanhu_4beifen from '../views/lanhu_4beifen/index.vue'
import lanhu_3beifen from '../views/lanhu_3beifen/index.vue'
import lanhu_1beifen from '../views/lanhu_1beifen/index.vue'
import policy from '../views/lanhu_1beifen/policy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/policy',
    name: "policy",
    component: policy
  },
  // {
  //   path: '/about',
  //   name: 'lanhu_2beifen',
  //   component: lanhu_2beifen
  // },
  // {
  //   path: '/contact',
  //   name: 'lanhu_4beifen',
  //   component: lanhu_4beifen
  // },
  // {
  //   path: '/services',
  //   name: 'lanhu_3beifen',
  //   component: lanhu_3beifen
  // },
  {
    path: '/home',
    name: 'lanhu_1beifen',
    component: lanhu_1beifen
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
