import { render, staticRenderFns } from "./policy.vue?vue&type=template&id=23a4c961&scoped=true"
import script from "./policy.vue?vue&type=script&lang=js"
export * from "./policy.vue?vue&type=script&lang=js"
import style0 from "./policy.vue?vue&type=style&index=0&id=23a4c961&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23a4c961",
  null
  
)

export default component.exports