import CryptoJS from 'crypto-js'
import axios from 'axios'

const baseKey = "cYFaFGaCbMLeVoBz"
const baseIv = "XgfOugRqyEDCcsvd"
const packageKey = "munrRRESTEDkSFyL"


const service = axios.create({
    // baseUrl: 'https://oundfort.potentialclientstolearn.com/ntstolea-gp/',
    baseUrl: 'http://test-oundfort.potentialclientstolearn.com/ntstolea-gp/',
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    },
    method: 'POST',
    responseType: 'json',
    transformRequest(data) {
        console.log(data)
        function encrypt(bkey, biv, data) {
            var key = CryptoJS.enc.Utf8.parse(bkey)
            var iv = CryptoJS.enc.Utf8.parse(biv)
            var encrypted = ''
            if (typeof data === 'string') {
                var srcs = CryptoJS.enc.Utf8.parse(data)
                encrypted = CryptoJS.AES.encrypt(srcs, key, {
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7,
                })
            }
            return encrypted.toString()
        }
        // return packageKey + encrypt(baseKey, baseIv, JSON.stringify(data))
        return packageKey + encrypt(baseKey.split("").reverse().join(""), baseIv.split("").reverse().join(""), JSON.stringify(data))

    },
})

// request interceptor
service.interceptors.request.use(
    (config) => {
        if (config.method === 'get' || config.method === 'GET') {
            if (config.data) {
                let dataStr = '' // 数据拼接字符串
                Object.keys(config.data).forEach((key) => {
                    dataStr += key + '=' + config.data[key] + '&'
                })
                if (dataStr !== '') {
                    dataStr = dataStr.substr(0, dataStr.lastIndexOf('&'))
                    config.url = config.url + '?' + dataStr
                }
            }
        }
        config.url = config.baseUrl + config.url
        console.log(config);
        return config
    },
    (error) => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    (response) => {
        if (response.status !== 200) {
            return Promise.reject(response)
        }
        const result = response.data.data
        if (!result) {
            return
        }
        var key = CryptoJS.enc.Utf8.parse(baseKey.split("").reverse().join(""))
        var iv = CryptoJS.enc.Utf8.parse(baseIv.split("").reverse().join(""))
        var decrypted = CryptoJS.AES.decrypt(result, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7, })
        const responseData = decrypted.toString(CryptoJS.enc.Utf8)
        console.log('--------456------');
        console.log(responseData);
        if (responseData === '') {
            console.log(result)
            return
        }

        const res = JSON.parse(responseData)
        console.log(response.config.url)
        console.log(res)

        if (res.code !== 0) {
            return Promise.reject(res)
        } else {
            return res
        }
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default service
