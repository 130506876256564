
import request from './request'

export function delInfoSearch(data) {
    return request({
        url: '/h5/del-info/search',
        method: 'POST',
        data,
    })
}
export function delInfoCanDelete(data) {
    return request({
        url: '/h5/del-info/can-delete',
        method: 'POST',
        data,
    })
}
export function delInfoSms(data) {
    return request({
        url: '/h5/del-info/sms',
        method: 'POST',
        data,
    })
}

export function delInfoDoDelete(data) {
    return request({
        url: '/h5/del-info/do-delete',
        method: 'POST',
        data,
    })
}
